import {
  getGlobalRules,
  getAllGlobalVariations,
  getOrder,
} from "./globalFunctions.js";

import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import { handleGenerateLabel } from "./handleGenerateLabel.js";

const handlePrintOrderPDFsTemp = (
  order,
  productionSheet,
  user,
  manufacturer,
  setLoadingModal,
  setOpen,
  setNoRuleOrders,
  setNoRule
) => {
  getGlobalRules(user.manufacturerID).then((res) => {
    if (res) {
      let globalRules = res;
      getOrder(order.order_id, user).then((data) => {
        let customHeads = new Set();
        let customer = order.customer_name;
        let store = order.store_name;
        let order_id = order.order_id.slice(-4);
        let orderItems = [];
        let noRule = false;
        let noRuleOrders = [];
        let orderNotes = [];

        getAllGlobalVariations(user.manufacturerID).then((data_) => {
          console.log(data);
          let error = 0;
          let history = [];
          let map = {};
          let map1 = {};
          data_.forEach((item) => {
            console.log("item", item);
            history.push(item);
            // check if the key is already in the map
            if (map[item.variation_title]) {
              // find the object in the history array
              let obj = history.find(
                (o) => o.variation_title === item.variation_title
              );
              // if the object is found, check the time createdAt and update the map with the latest object

              if (obj.isDeleted && !item.isDeleted) {
                console.log("item", item);

                map[item.variation_title] = item?.key
                  ? item.key
                  : "Customizations";
                map1[item.variation_title] = item?.isRule ? item.isRule : false;

                // remove the old object from the history array
                history = history.filter(
                  (o) => o.variation_title !== item.variation_title
                );

                // add the new object to the history array
                history.push(item);
              }
            } else {
              if (!item?.key) {
                console.log("item", item);
              }

              map[item.variation_title] = item?.key
                ? item.key
                : item.variation_title;
              map1[item.variation_title] = item?.isRule ? item.isRule : false;
            }
          });

          const keyAdditionMap = map;
          console.log("keyAdditionMap", keyAdditionMap);

          const isRuleAdditionMap = map1;

          const productionSheets = manufacturer.productionSheets;

          data?.orders.forEach((order, index) => {
            let orderItem = {
              fabric_name: order.selectedProduct.fabric_name,
              width: order.user_inputs.width,
              height: order.user_inputs.height,
              metricSystem: order.user_inputs.measurement_type,
              fractions: order.user_inputs?.fractions,
              room_name: order.user_inputs.room_name,
              quantity: order.quantity,
              note: order.notes,
            };

            let customizations = [];

            Object.keys(order.variants).forEach((key) => {
              let item = {};

              let k = keyAdditionMap[key];

              if (
                handleAddDetailsTemp(order.variants[key].details).length > 0
              ) {
                item = {
                  [k]: {
                    value: order.variants[key].value,
                    details: handleAddDetailsTemp(order.variants[key].details),
                  },
                };
              } else {
                item = {
                  [k]: {
                    value: order.variants[key].value,
                  },
                };
              }
              customizations.push(item);
              customHeads.add(k);
            });

            orderItem.customizations = customizations;

            let matchingRule = {};
            let keysLength = 0;
            Object.keys(order.variants).forEach((key) => {
              if (isRuleAdditionMap[key]) {
                console.log("key2", key);
                keysLength++;
              }
            });

            console.log("keysLength", keysLength);
            let variantsLength = Object.keys(order.variants).length;
            console.log("isRule", isRuleAdditionMap);
            if (!noRule) {
              for (let i = 0; i < globalRules.length; i++) {
                console.log(globalRules[i].product_type)
                if (
                  globalRules[i].product_type ===
                  order.selectedProduct.design_type
                ) {
                  //firs criteria for the rule
                  let match = 0;
                  let ruleLength = Object.keys(globalRules[i].rules).length;
                  console.log("ruleLength", ruleLength);
                  console.log("globalRules[i].rules", globalRules[i]);
                  Object.keys(globalRules[i].rules).forEach((key) => {
                    if (
                      order.variants[key]?.value.trim() ===
                      globalRules[i].rules[key].trim()
                    ) {
                      if (isRuleAdditionMap[key]) {
                        match++;
                      }
                    }
                  });
                  console.log(keysLength,ruleLength,match, globalRules[i].ruleName);

                  if (match === keysLength && variantsLength === ruleLength) {
                    console.log("ruleLength", ruleLength);
                    matchingRule = globalRules[i];
                    break;
                  }
                }
              }
            }

            if (Object.keys(matchingRule).length === 0) {
              noRule = true;
              console.log("no rule", order);
              noRuleOrders.push(order);
              setLoadingModal(false);
              setOpen(false);
              return;
            }

            orderItem.matchingRule = matchingRule;
            orderItem.index = index + 1;
            orderItems.push(orderItem);

            if (order.notes?.length > 0)
              orderNotes.push({ index: index, note: order.notes });
          });

          if (noRule) {
            console.log("noRuleOrders", noRuleOrders);
            setNoRuleOrders(noRuleOrders);
            setNoRule(true);
            return;
          }



          // const labelSpecs = {
          //   pageWidth: 612, // 8.5 inches * 72 points/inch
          //   pageHeight: 792, // 11 inches * 72 points/inch
          //   labelWidth: 180, // Adjust according to label size
          //   labelHeight: 72, // Adjust according to label size
          //   marginX: 10, // Space between labels horizontally
          //   marginY: 10, // Space between labels vertically
          //   paddingX: 5, // Padding inside labels horizontally
          //   paddingY: 15, // Padding inside labels vertically
          //   labelsPerRow: 3, // Number of labels per row
          //   labelsPerColumn: 10, // Number of labels per column
          // };

          console.log(orderItems);
          if (productionSheet === "label1") {
            return handleGenerateLabel(
              orderItems,
              data,
              "label1",
              setOpen,
              setLoadingModal,
              manufacturer
            );
          }

          ///Currently not in use
          // if (productionSheet === "label2") {
          //   return handleGenerateLabel(orderItems, data, "label2");
          // }

          let orders_ = [];

          orderItems.forEach((order) => {
            let {
              cassetteCut,
              tubeCut,
              bottomBar,
              bottomTube,
              fabricWidth,
              fabricLength,
            } = handleCuttingRules(order, manufacturer);
            let matchingRule = order.matchingRule;
            let customizations = order.customizations;
            let roomName = order.room_name;
            let fabricName = order.fabric_name;
            let quantity = order.quantity;
            let note = order.note;
            let width = convertToManufacturerUnit(
              order,
              order.metricSystem,
              manufacturer
            ).width;
            let height = convertToManufacturerUnit(
              order,
              order.metricSystem,
              manufacturer
            ).height;
            let customs = {};

            console.log("customizations", customizations);

            for (let i = 0; i < customizations.length; i++) {
              let custom = customizations[i];
              let keys = Object.keys(custom);
              let key = keys[0]; // The key you're working with
              console.log("key", keys); // Debug: Check the key

              let value = custom[key].value;
              let details = custom[key].details;
              let stringBuilder = value;
              console.log(stringBuilder); // Debug: Check if value is correct

              let ops = productionSheet.options;
              console.log(ops);
              console.log(key);
              let index = ops.indexOf(key);
              console.log(index);
              let isShowKey = true;
              if (index !== -1) {
                console.log(productionSheet.settings);
                let setting = productionSheet.settings[index];
                console.log(setting);
                isShowKey = setting.settings.showKeys; // check if the key should be shown
              }

              console.log(isShowKey);

              if (details) {
                stringBuilder = value + "\n"; // Add a newline if there are details
                for (let j = 0; j < details.length; j++) {
                  let detail = details[j];
                  if(detail.value.toUpperCase() === "NO") continue;
                  if (isShowKey) {
                    stringBuilder +=
                      " -" + detail.key + ": " + detail.value + "\n"; // Append details
                  } 
                  else {
                    stringBuilder += " -" + detail.value + "\n"; // Append details
                  }
                }
                // remove the last newline character
                stringBuilder = stringBuilder.slice(0, -1);
              }

              // Initialize customs[key] if it doesn't exist, to avoid appending to undefined
              if (!customs[key]) {
                customs[key] = ""; // Initialize as an empty string if not present
              }

              // Append the stringBuilder to customs[key]
              customs[key] += stringBuilder;
            }

            console.log("customs", customs); // Debug: Check the final customs object

            let o = {
              "Room Name": roomName,
              "Fabric Name": fabricName,
              Quantity: quantity,
              Width: width,
              Height: height,
              Notes: note,
              "Cassette Cut": cassetteCut,
              "Tube Cut": tubeCut,
              "Bottom Bar Cut": bottomBar,
              "Bottom Tube Cut": bottomTube,
              "Fabric Width Cut": fabricWidth,
              "Fabric Height Cut": fabricLength,
              "Matching Rule": matchingRule.ruleName,
              ...customs,
            };
            orders_.push(o);
          });

          console.log("orders_", orders_);

          let options = productionSheet.options;
          console.log("options", options);
          let title = productionSheet.title;
          let settings = productionSheet.settings;

          let head = options;

          let body = [];
          let notFounds = [];
          orders_.forEach((order, index) => {
            let row = [];
            row.push(index + 1);

            head.forEach((key) => {
              let value = order[key];
              console.log("value", value);

              row.push(value);
            });
            body.push(row);
          });

          if (notFounds.length > 0) {
            console.log("notFounds", notFounds);
          }

          console.log("head", head);
          console.log("body", body);

          let customerName = order.customer_name;
          let storeName = order.store_name;
          let orderID = order.order_id.slice(-4);

          // Step 1: Filter out columns in 'head' where all corresponding 'body' entries are undefined
          let emptyColumns = [];
          let emptyHeads = [];

          let head_ = head.filter((item, index) => {
            index = index + 1; // Increment index by 1 to account for the 'No.' column
            let found = false;

            // Check if this column has any non-undefined entries in the body
            body.forEach((row) => {
              if (row[index] !== undefined && row[index] !== null) {
                found = true;
              }
            });

            // If no defined values are found, mark this column as empty
            if (!found) {
              emptyColumns.push(index);
              emptyHeads.push(item);
            }

            return found; // Keep the column if it has defined values
          });

          // Step 2: Remove the corresponding columns from each row in the body
          body = body.map((row) => {
            return row.filter((item, index) => !emptyColumns.includes(index));
          });

          // remove the empty columns from the settings
          let settings_ = settings.filter(
            (item) => !emptyHeads.includes(item.option)
          );

          if (error) {
            setLoadingModal(false);
            return;
          }

          return handleGeneratePdf(
            [["Item #", ...head_]],
            body,
            settings_,
            title,
            storeName,
            customerName,
            orderID,
            orderNotes,
            setOpen,
            setLoadingModal
          );
        });
      });
    }
  });
};

const handleGeneratePdf = (
  head,
  body,
  settings,
  title,
  storeName,
  customerName,
  orderID,
  orderNotes,
  setOpen,
  setLoadingModal
) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Header Title
  doc.setFontSize(13);
  doc.setFont("helvetica", "bold");
  doc.setTextColor(40);
  doc.text(title, pageWidth / 2, 15, { align: "center" });

  // Combine Order ID, Store Name, Customer Name, and Date
  doc.setFontSize(10);
  doc.setFont("helvetica", "italic");
  const headerInfo = `Order ID: ${orderID}    Store: ${storeName}    Customer: ${customerName}    Date: ${new Date().toLocaleDateString()}`;
  doc.text(headerInfo, pageWidth / 2, 25, { align: "center" });

  // Separator Line
  doc.line(10, 28, pageWidth - 10, 28);

  // Column styles based on the settings
  let columnStyles = {};
  settings.forEach((setting) => {
    let index = head[0].indexOf(setting.option);
    columnStyles[index] = {
      cellWidth: "auto",
      valign: "middle",
      fontStyle: setting.settings.bold ? "bold" : "normal",
      fontSize: parseInt(setting.settings.fontSize),
    };
  });

  // Adjust margins to reduce top and bottom spaces
  const margins = {
    top: 30, // Reduced from 40 to 30
    bottom: 20,
    left: 10,
    right: 10,
  };

  // AutoTable configuration with optimized settings
  autoTable(doc, {
    startY: margins.top,
    theme: "grid",
    headStyles: {
      fillColor: [230, 230, 230],
      textColor: [0, 0, 0],
      fontSize: 8,
      fontStyle: "bold",
    },
    bodyStyles: {
      fontSize: 9,
      textColor: [60, 60, 60],
      lineColor: [200, 200, 200],
      lineWidth: 0.5,
      cellPadding: 1,
      overflow: "linebreak",
      valign: "middle",
      minCellHeight: 10,
    },
    columnStyles: {
      0: { cellWidth: 10, halign: "center", valign: "middle" },
      ...columnStyles,
    },
    head: head,
    body: body,
    margin: margins,
    tableWidth: "auto",
    showHead: "everyPage",
    rowPageBreak: "avoid",
    pageBreak: "auto",
    didDrawPage: (data) => {
      // Footer on every page
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.setFont("helvetica", "italic");
      doc.text(
        `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`,
        pageWidth - 50,
        pageHeight - 10
      );
    },
  });

  // Save the PDF
  doc.save(`${customerName}.pdf`);
  setLoadingModal(false);
  setOpen(false);
};


let convertToManufacturerUnit = (order, unit, manufacturer) => {
  if (manufacturer.metricSystem === "cm" && unit === "inch") {
    // inch to cm
    let width = parseFloat(order.width);
    let height = parseFloat(order.height);
    let heightFraction = order?.fractions?.height
      ? order?.fractions?.height
      : 0;
    let widthFraction = order?.fractions?.width ? order?.fractions?.width : 0;

    let decimalWidth = convertToDecimal(width, widthFraction);
    let decimalHeight = convertToDecimal(height, heightFraction);

    let cmWidth = inchesToCm(decimalWidth);
    let cmHeight = inchesToCm(decimalHeight);

    return {
      width: cmWidth.toFixed(2),
      height: cmHeight.toFixed(2),
    };
  }
  if (manufacturer.metricSystem === "inch" && unit === "cm") {
    // cm to inch
    let width = parseFloat(order.width);
    let height = parseFloat(order.height);
    width =  cmToInches(width);
    height = cmToInches(height);
    let inchWidth = convertToFraction(width);
    let inchHeight = convertToFraction(height);

    return {
      width: inchWidth.whole + " " + inchWidth.fraction,
      height: inchHeight.whole + " " + inchHeight.fraction,
    };
  }
  if (manufacturer.metricSystem === "cm" && unit === "cm") {
    let width = parseFloat(order.width);
    let height = parseFloat(order.height);

    return {
      width: width.toFixed(2),
      height: height.toFixed(2),
    };
  }

  if (manufacturer.metricSystem === "inch" && unit === "inch") {
    return {
      width: order.width + " " + order?.fractions?.width,
      height: order.height + " " + order?.fractions?.height,
    };
  }
};

const handleAddDetailsTemp = (details) => {
  try {
    console.log("details", details);

    if (Object.keys(details).length === 0) return "";

    const sortedKeys = Object.keys(details).sort((a, b) => a.localeCompare(b));

    const newDetails = sortedKeys.map((key) => ({
      key: key,
      value: details[key]?.value || null,
    }));

    return newDetails;
  } catch (error) {
    console.error("Error in handleAddDetailsTemp:", error);
    return [];
  }
};


const convertToFraction = (decimal) => {
  console.log("decimal1", decimal);
  if (decimal === "none") {
    return {
      whole: 0,
      fraction: 0,
    };
  }

  console.log("decimal3", decimal);

  // let inch_decimal_ =   cmToInches(decimal);
  // const wholeNumber = Math.floor(inch_decimal_);
  // const fraction = inch_decimal_ - wholeNumber;

  const wholeNumber = Math.floor(decimal);
  const fraction = decimal - wholeNumber;
  const closestFraction = convertToClosestFraction(fraction, 16); // Using 16 as the maximum denominator

  return {
    whole: wholeNumber,
    fraction: closestFraction,
  };
};

const convertToDecimal = (whole, fraction) => {
  // Convert the whole part to a number
  const wholeNumber = parseFloat(whole);

  // If fraction is a valid fraction string (like "1/4"), split and calculate it
  if (fraction && fraction.includes("/")) {
    const [numerator, denominator] = fraction.split("/").map(Number);
    return wholeNumber + numerator / denominator;
  }

  // If no fraction or fraction is not a valid format, just return the whole number
  return wholeNumber;
};

const inchesToCm = (inches) => {
  return inches * 2.54;
};

const cmToInches = (cm) => {
  return cm / 2.54;
};

const convertToClosestFraction = (fraction, maxDenominator = 16) => {
  const denominator = maxDenominator;
  const numerator = Math.round(fraction * denominator);

  // If the numerator is 0 or equal to the denominator, adjust the fraction
  if (numerator === 0) {
    return "";
  } else if (numerator === denominator) {
    return `${numerator / denominator}`;
  } else if(!numerator || !denominator) {
    return "";
  }else{

    // Reduce the fraction to its simplest form
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(numerator, denominator);
    return `${numerator / divisor}/${denominator / divisor}`;
  }
};

const handleCuttingRules = (order, manufacturer) => {
  console.log("manufacturer" + manufacturer);
  let width, height;
  let cassetteCut, tubeCut, bottomBar, bottomTube, fabricWidth, fabricLength;
  //todo custom functions to deduct the values(check fabricLength)
  if (manufacturer.metricSystem === "cm" && order.metricSystem === "inch") {
    width = inchesToCm(convertToDecimal(order.width, order.fractions?.width));
    height = inchesToCm(
      convertToDecimal(order.height, order.fractions?.height)
    );

    cassetteCut = (
      width - parseFloat(order.matchingRule.deductions.cassetteCut)
    ).toFixed(2);
    tubeCut = (
      width - parseFloat(order.matchingRule.deductions.tubeCut)
    ).toFixed(2);
    bottomBar = (
      width - parseFloat(order.matchingRule.deductions.bottomBar)
    ).toFixed(2);
    bottomTube = (
      width - parseFloat(order.matchingRule.deductions.bottomTube)
    ).toFixed(2);
    fabricWidth = (
      width - parseFloat(order.matchingRule.deductions.fabricWidth)
    ).toFixed(2);
    fabricLength = (2 * height + 30).toFixed(2);
  }
  if (manufacturer.metricSystem === "inch" && order.metricSystem === "cm") {
    width = cmToInches(parseFloat(order.width));
    height = cmToInches(parseFloat(order.height));

    cassetteCut = convertToFraction(
      width -
        convertToDecimal(
          convertToFraction(order.matchingRule.deductions.cassetteCut).whole,
          convertToFraction(order.matchingRule.deductions.cassetteCut).fraction
        )
    );
    tubeCut = convertToFraction(width - order.matchingRule.deductions.tubeCut);
    bottomBar = convertToFraction(
      width - order.matchingRule.deductions.bottomBar
    );
    bottomTube = convertToFraction(
      width - order.matchingRule.deductions.bottomTube
    );
    fabricWidth = convertToFraction(
      width - order.matchingRule.deductions.fabricWidth
    );

    fabricLength =
      height + parseFloat(order.matchingRule.deductions.fabricLength);

    fabricLength = convertToFraction(fabricLength);

    cassetteCut = cassetteCut.whole + " " + cassetteCut.fraction;
    tubeCut = tubeCut.whole + " " + tubeCut.fraction;
    bottomBar = bottomBar.whole + " " + bottomBar.fraction;
    bottomTube = bottomTube.whole + " " + bottomTube.fraction;
    fabricWidth = fabricWidth.whole + " " + fabricWidth.fraction;
    fabricLength = fabricLength.whole + " " + fabricLength.fraction;
  }

  if (manufacturer.metricSystem === "inch" && order.metricSystem === "inch") {
    width = convertToDecimal(order.width, order.fractions?.width);
    height = convertToDecimal(order.height, order.fractions?.height);

    console.log(width, height);
    let cassetteCutDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.cassetteCut).whole,
      convertToFraction(order.matchingRule.deductions.cassetteCut).fraction
    );
    console.log(cassetteCutDecimal);
    let tubeCutDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.tubeCut).whole,
      convertToFraction(order.matchingRule.deductions.tubeCut).fraction
    );
    console.log(tubeCutDecimal);
    console.log(order.matchingRule);
    let bottomBarDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.bottomBar).whole,
      convertToFraction(order.matchingRule.deductions.bottomBar).fraction
    );
    console.log(bottomBarDecimal);
    let bottomTubeDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.bottomTube).whole,
      convertToFraction(order.matchingRule.deductions.bottomTube).fraction
    );
    console.log(bottomTubeDecimal);
    let fabricWidthDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.fabricWidth).whole,
      convertToFraction(order.matchingRule.deductions.fabricWidth).fraction
    );
    console.log(fabricWidthDecimal);
    let fabricLengthDecimal = convertToDecimal(
      convertToFraction(order.matchingRule.deductions.fabricLength).whole,
      convertToFraction(order.matchingRule.deductions.fabricLength).fraction
    );
    console.log(fabricLengthDecimal);
    
    cassetteCut = convertToFraction(width - cassetteCutDecimal);
    tubeCut = convertToFraction(width - tubeCutDecimal);
    bottomBar = convertToFraction(width - bottomBarDecimal);
    bottomTube = convertToFraction(width - bottomTubeDecimal);
    fabricWidth = convertToFraction(width - fabricWidthDecimal);
    fabricLength = convertToFraction(height + fabricLengthDecimal);
    console.log(cassetteCut);
    cassetteCut = cassetteCut.whole + " " + cassetteCut.fraction;
    tubeCut = tubeCut.whole + " " + tubeCut.fraction;
    bottomBar = bottomBar.whole + " " + bottomBar.fraction;
    bottomTube = bottomTube.whole + " " + bottomTube.fraction;
    fabricWidth = fabricWidth.whole + " " + fabricWidth.fraction;
    fabricLength = fabricLength.whole + " " + fabricLength.fraction;

    if (order.matchingRule.deductions.cassetteCut === "none") {
      cassetteCut = "N/A";
    }
    if (order.matchingRule.deductions.tubeCut === "none") {
      tubeCut = "N/A";
    }
    if (order.matchingRule.deductions.bottomBar === "none") {
      bottomBar = "N/A";
    }
    if (order.matchingRule.deductions.bottomTube === "none") {
      bottomTube = "N/A";
    }
    if (order.matchingRule.deductions.fabricWidth === "none") {
      fabricWidth = "N/A";
    }
    if (order.matchingRule.deductions.fabricLength === "none") {
      fabricLength = "N/A";
    }
  }

  if (manufacturer.metricSystem === "cm" && order.metricSystem === "cm") {
    //only cachet admin for now
    width = parseFloat(order.width);
    height = parseFloat(order.height);

    cassetteCut = (
      width - parseFloat(order.matchingRule.deductions.cassetteCut)
    ).toFixed(2);
    tubeCut = (
      width - parseFloat(order.matchingRule.deductions.tubeCut)
    ).toFixed(2);
    bottomBar = (
      width - parseFloat(order.matchingRule.deductions.bottomBar)
    ).toFixed(2);
    bottomTube = (
      width - parseFloat(order.matchingRule.deductions.bottomTube)
    ).toFixed(2);
    fabricWidth = (
      width - parseFloat(order.matchingRule.deductions.fabricWidth)
    ).toFixed(2);
    fabricLength = (height * 2 + 30).toFixed(2); // TODO:: custom logics for each businesses

    if (order.matchingRule.deductions.cassetteCut === "none") {
      cassetteCut = "N/A";
    }
    if (order.matchingRule.deductions.tubeCut === "none") {
      tubeCut = "N/A";
    }
    if (order.matchingRule.deductions.bottomBar === "none") {
      bottomBar = "N/A";
    }
    if (order.matchingRule.deductions.bottomTube === "none") {
      bottomTube = "N/A";
    }
    if (order.matchingRule.deductions.fabricWidth === "none") {
      fabricWidth = "N/A";
    }
    if (order.matchingRule.deductions.fabricLength === "none") {
      fabricLength = "N/A";
    }
  }

  return {
    cassetteCut,
    tubeCut,
    bottomBar,
    bottomTube,
    fabricWidth,
    fabricLength,
  };
};


export { handlePrintOrderPDFsTemp };
