import React,{useState,useEffect} from 'react'
import { Dialog } from '@headlessui/react'
import { PencilIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';
import { updateManufacturer,getGlobalVariations } from '../../utils/globalFunctions';
import LabelSettings from './LabelSettings';


const transformToFirestore = (labelSettings) => {
    return labelSettings?.map((line, index) => ({
        lineIndex: index,
        fields: line
    }));
};

const transformFromFirestore = (firestoreLabelSettings) => {
    return firestoreLabelSettings.map((line) => line.fields);
};
export default function Index({user,manufacturer}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsSettings, setSelectedOptionsSettings] = useState([]);
    const [productionSheetTitle, setProductionSheetTitle] = useState("");
    const [productionSheets, setProductionSheets] = useState([]);
    const [selectedLabelOptions, setSelectedLabelOptions] = useState([]);
    const [selectedLabelOptionsSettings, setSelectedLabelOptionsSettings] = useState([]);
    const [isGroup, setIsGroup] = useState(false);
    const [productionSheetOptions, setProductionSheetOptions] = useState([]);
    const [labelOptions, setLabelOptions] = useState([]);
    const [labelSettings, setLabelSettings] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editTitle, setEditTitle] = useState("");
    const [editSelectedOptions, setEditSelectedOptions] = useState([]);
    const [editSelectedOptionsSettings, setEditSelectedOptionsSettings] = useState([]);
    const [sheetToEdit, setSheetToEdit] = useState(null);
   

    const handleSaveProductionSheets = async () => {
        console.log(labelSettings)
        try {
          const updatedManufacturer = {
            ...manufacturer,
            productionSheets,
            id: user.manufacturerID,
            labelSettings: transformToFirestore(labelSettings),
          };
          await updateManufacturer(updatedManufacturer);
          toast.success("Settings saved successfully");
        } catch (error) {
          toast.error("Failed to save settings");
        }
      };

    const handleDeleteProductionSheet = (sheetTitle) => {
        if (
          window.confirm("Are you sure you want to delete this production sheet?")
        ) {
          const updatedSheets = productionSheets.filter(
            (item) => item.title !== sheetTitle
          );
          setProductionSheets(updatedSheets);
          toast.success("Production sheet deleted");
        }
      };
    
      const handleDeleteLabelSettings = () => {
        if (window.confirm("Are you sure you want to clear this label settings?")) {
          setSelectedLabelOptionsSettings([]);
          setSelectedLabelOptions([]);
          setLabelSettings({});
          toast.success("Label settings deleted");
        }
      };

  const openEditModal = (sheet) => {
    setSheetToEdit(sheet);
    setEditTitle(sheet.title);
    setEditSelectedOptions(sheet.options);
    setEditSelectedOptionsSettings(sheet.settings);
    setIsEditModalOpen(true);
  };

    const createProductionSheet = () => {
        if (productionSheetTitle.trim() === "") {
          toast.error("Please enter a production sheet title");
          return;
        }
        const newSheet = {
          title: productionSheetTitle.trim(),
          options: selectedOptions,
          settings: selectedOptionsSettings,
        };
        setProductionSheets([...productionSheets, newSheet]);
        setSelectedOptions([]);
        setSelectedOptionsSettings([]);
        setProductionSheetTitle("");
        toast.success("Production sheet created");
      };

      const saveEditedSheet = () => {
        if (editTitle.trim() === "") {
          toast.error("Please enter a production sheet title");
          return;
        }
        const updatedSheet = {
          title: editTitle.trim(),
          options: editSelectedOptions,
          settings: editSelectedOptionsSettings,
        };
        setProductionSheets(
          productionSheets.map((sheet) =>
            sheet.title === sheetToEdit.title ? updatedSheet : sheet
          )
        );
        setIsEditModalOpen(false);
        toast.success("Production sheet updated");
      };

   
      const extractKeys = (data) => {
        const keys = new Set();
    
        data.forEach((item) => {
            const { key, variationItems } = item;
    
            // Add first-level key
            keys.add(key);
    
            if (variationItems) {
                variationItems.forEach((variation) => {
                    // Add third-level keys for variation details
                    if (variation.variation_details) {
                        variation.variation_details.forEach((detail) => {
                            const thirdLevelKey = `${key}.${detail.variation_detail_title}`;
                            keys.add(thirdLevelKey);
                        });
                    }
                });
            }
        });
    
        return Array.from(keys);
    };
    useEffect(() => {
        if (manufacturer) {
          const productionSheetKeys = manufacturer.productionSheetKeys || [];
          const defaultOptions = [
            "Fabric Name",
            "Width",
            "Height",
            "Room Name",
            "Quantity",
            "Notes",
            "Matching Rule",
            "Cassette Cut",
            "Tube Cut",
            "Bottom Bar Cut",
            "Bottom Tube Cut",
            "Fabric Width Cut",
            "Fabric Height Cut",
            ...productionSheetKeys,
          ];

          getGlobalVariations(user.manufacturerID).then((docs)=>{

           let keys =  extractKeys(docs)

            let flattenedData = []

            for (let doc of docs) {

            }

            const defaultLabelOptions = [
                "Item No",
                "Fabric Name",
                "Customer Name",
                "Store Name",
                "Measurement",
                "Room Name",
                "Quantity",
                "Date",
                ...keys
              ];

              setProductionSheetOptions(defaultOptions);
              setLabelOptions(defaultLabelOptions);
              setProductionSheets(manufacturer.productionSheets || []);
              console.log(manufacturer.labelSettings)
              setLabelSettings(manufacturer?.labelSettings ? transformFromFirestore(manufacturer?.labelSettings) : []);
              setSelectedLabelOptions(manufacturer.labelSettings?.options || []);
              setSelectedLabelOptionsSettings(
                manufacturer.labelSettings?.settings || []
              );
        
          })


    
        }
      }, [manufacturer]);


  return (
  <>
        {/* Production Sheet Options */}
        <div className="mt-10">
        <h1 className='text-2xl font-bold mt-4 mb-4 text-center'>Production Sheets & Label Settings</h1>
        <h2 className="text-xl font-semibold text-gray-800">
          Production Sheet Options
        </h2>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {productionSheetOptions.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                id={`option-${index}`}
                onChange={() => {
                  if (selectedOptions.includes(option)) {
                    setSelectedOptions(
                      selectedOptions.filter((item) => item !== option)
                    );
                    setSelectedOptionsSettings(
                      selectedOptionsSettings.filter(
                        (item) => item.option !== option
                      )
                    );
                  } else {
                    setSelectedOptions([...selectedOptions, option]);
                    setSelectedOptionsSettings([
                      ...selectedOptionsSettings,
                      {
                        option: option,
                        settings: {
                          fontSize: 12,
                          bold: false,
                          showKeys: true,
                        },
                      },
                    ]);
                  }
                }}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <label
                htmlFor={`option-${index}`}
                className="ml-2 block text-sm text-gray-700"
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Selected Options Settings */}
      {selectedOptions.length > 0 && (
        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-800">
            Configure Production Sheet
          </h2>
          <div className="mt-4">
            <label
              htmlFor="productionSheetTitle"
              className="block text-sm font-medium text-gray-700"
            >
              Production Sheet Title
            </label>
            <input
              type="text"
              id="productionSheetTitle"
              value={productionSheetTitle}
              onChange={(e) => setProductionSheetTitle(e.target.value)}
              className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter title"
            />
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900">
              Selected Options
            </h3>
            <div className="mt-4 space-y-4">
              {selectedOptions.map((option, index) => {
                const settings =
                  selectedOptionsSettings.find((item) => item.option === option)
                    ?.settings || {};
                return (
                  <div
                    key={index}
                    className="bg-white shadow sm:rounded-md p-4"
                  >
                    <h4 className="text-md font-medium text-gray-800">
                      {option}
                    </h4>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                      {/* Font Size */}
                      <div>
                        <label
                          htmlFor={`fontSize-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Font Size
                        </label>
                        <input
                          type="number"
                          id={`fontSize-${index}`}
                          value={settings.fontSize}
                          onChange={(e) => {
                            const updatedSettings = {
                              ...settings,
                              fontSize: e.target.value,
                            };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option
                                  ? { option, settings: updatedSettings }
                                  : item
                              )
                            );
                          }}
                          className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      {/* Bold */}
                      <div className="flex items-center mt-4 sm:mt-0">
                        <input
                          type="checkbox"
                          id={`bold-${index}`}
                          checked={settings.bold}
                          onChange={(e) => {
                            const updatedSettings = {
                              ...settings,
                              bold: e.target.checked,
                            };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option
                                  ? { option, settings: updatedSettings }
                                  : item
                              )
                            );
                          }}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={`bold-${index}`}
                          className="ml-2 block text-sm text-gray-700"
                        >
                          Bold
                        </label>
                      </div>
                      {/* Show Keys */}
                      <div className="flex items-center mt-4 sm:mt-0">
                        <input
                          type="checkbox"
                          id={`showKeys-${index}`}
                          checked={settings.showKeys}
                          onChange={(e) => {
                            const updatedSettings = {
                              ...settings,
                              showKeys: e.target.checked,
                            };
                            setSelectedOptionsSettings(
                              selectedOptionsSettings.map((item) =>
                                item.option === option
                                  ? { option, settings: updatedSettings }
                                  : item
                              )
                            );
                          }}
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={`showKeys-${index}`}
                          className="ml-2 block text-sm text-gray-700"
                        >
                          Show Keys
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-6 flex space-x-4">
              <button
                onClick={() => {
                  setSelectedOptions([]);
                  setSelectedOptionsSettings([]);
                }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear
              </button>
              <button
                onClick={createProductionSheet}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Create Production Sheet
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Production Sheets List */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">
          Production Sheets
        </h2>
        {productionSheets.length > 0 ? (
          <div className="mt-4 space-y-4">
            {productionSheets.map((sheet, index) => (
              <div key={index} className="bg-white shadow sm:rounded-md p-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-gray-900">
                    {sheet.title}
                  </h3>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => openEditModal(sheet)}
                      className="text-blue-600 hover:text-blue-900 flex items-center"
                    >
                      <PencilIcon className="h-5 w-5 mr-1" />
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProductionSheet(sheet.title)}
                      className="text-red-600 hover:text-red-900 flex items-center"
                    >
                      <XMarkIcon className="h-5 w-5 mr-1" />
                      Delete
                    </button>
                  </div>
                </div>
                <ul className="mt-4 space-y-2">
                  {sheet.options.map((option, idx) => (
                    <li key={idx} className="text-sm text-gray-700">
                      <span className="font-medium">{option}</span>
                      <ul className="ml-4 mt-1 text-xs text-gray-500">
                        <li>
                          Font size:{" "}
                          {
                            sheet.settings.find(
                              (item) => item.option === option
                            )?.settings.fontSize
                          }
                        </li>
                        <li>
                          Bold:{" "}
                          {sheet.settings.find((item) => item.option === option)
                            ?.settings.bold
                            ? "Yes"
                            : "No"}
                        </li>
                        <li>
                          Show keys:{" "}
                          {sheet.settings.find((item) => item.option === option)
                            ?.settings.showKeys
                            ? "Yes"
                            : "No"}
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-4 text-sm text-gray-500">
            No production sheets found.
          </p>
        )}
      </div>

      {/* Label Options */}

        <p className="text-xl font-semibold text-gray-800 mt-10">
            Label Settings
        </p>

        <LabelSettings 
            labelSettings={labelSettings}
            setLabelSettings={setLabelSettings}
            labelOptions={labelOptions}
            />


      {/* Save Button */}
      <div className="mt-10">
        <button
          onClick={handleSaveProductionSheets}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
        >
          Save Settings
        </button>
      </div>

        
      {/* Edit Production Sheet Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <div className="relative bg-white rounded max-w-2xl mx-auto w-full p-6 overflow-y-auto max-h-screen">
              <Dialog.Title className="text-lg font-medium text-gray-900">
                Edit Production Sheet
              </Dialog.Title>
              {/* Modal content goes here */}
              {/* [Same as the create production sheet form, but using edit state variables] */}
              {/* ... */}
              <div className="mt-4">
                <label
                  htmlFor="editTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Production Sheet Title
                </label>
                <input
                  type="text"
                  id="editTitle"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  placeholder="Enter title"
                />
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900">Options</h3>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {productionSheetOptions.map((option, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={editSelectedOptions.includes(option)}
                        id={`edit-option-${index}`}
                        onChange={() => {
                          if (editSelectedOptions.includes(option)) {
                            setEditSelectedOptions(
                              editSelectedOptions.filter(
                                (item) => item !== option
                              )
                            );
                            setEditSelectedOptionsSettings(
                              editSelectedOptionsSettings.filter(
                                (item) => item.option !== option
                              )
                            );
                          } else {
                            setEditSelectedOptions([
                              ...editSelectedOptions,
                              option,
                            ]);
                            setEditSelectedOptionsSettings([
                              ...editSelectedOptionsSettings,
                              {
                                option: option,
                                settings: {
                                  fontSize: 12,
                                  bold: false,
                                  showKeys: true,
                                },
                              },
                            ]);
                          }
                        }}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                      <label
                        htmlFor={`edit-option-${index}`}
                        className="ml-2 block text-sm text-gray-700"
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Selected Options Settings */}
              {editSelectedOptions.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900">
                    Configure Options
                  </h3>
                  <div className="mt-4 space-y-4">
                    {editSelectedOptions.map((option, index) => {
                      const settings =
                        editSelectedOptionsSettings.find(
                          (item) => item.option === option
                        )?.settings || {};
                      return (
                        <div
                          key={index}
                          className="bg-gray-50 border rounded-md p-4"
                        >
                          <h4 className="text-md font-medium text-gray-800">
                            {option}
                          </h4>
                          <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                            {/* Font Size */}
                            <div>
                              <label
                                htmlFor={`edit-fontSize-${index}`}
                                className="block text-sm font-medium text-gray-700"
                              >
                                Font Size
                              </label>
                              <input
                                type="number"
                                id={`edit-fontSize-${index}`}
                                value={settings.fontSize}
                                onChange={(e) => {
                                  const updatedSettings = {
                                    ...settings,
                                    fontSize: e.target.value,
                                  };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option
                                        ? { option, settings: updatedSettings }
                                        : item
                                    )
                                  );
                                }}
                                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>
                            {/* Bold */}
                            <div className="flex items-center mt-4 sm:mt-0">
                              <input
                                type="checkbox"
                                id={`edit-bold-${index}`}
                                checked={settings.bold}
                                onChange={(e) => {
                                  const updatedSettings = {
                                    ...settings,
                                    bold: e.target.checked,
                                  };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option
                                        ? { option, settings: updatedSettings }
                                        : item
                                    )
                                  );
                                }}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label
                                htmlFor={`edit-bold-${index}`}
                                className="ml-2 block text-sm text-gray-700"
                              >
                                Bold
                              </label>
                            </div>
                            {/* Show Keys */}
                            <div className="flex items-center mt-4 sm:mt-0">
                              <input
                                type="checkbox"
                                id={`edit-showKeys-${index}`}
                                checked={settings.showKeys}
                                onChange={(e) => {
                                  const updatedSettings = {
                                    ...settings,
                                    showKeys: e.target.checked,
                                  };
                                  setEditSelectedOptionsSettings(
                                    editSelectedOptionsSettings.map((item) =>
                                      item.option === option
                                        ? { option, settings: updatedSettings }
                                        : item
                                    )
                                  );
                                }}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                              <label
                                htmlFor={`edit-showKeys-${index}`}
                                className="ml-2 block text-sm text-gray-700"
                              >
                                Show Keys
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setIsEditModalOpen(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={saveEditedSheet}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
  </>

  )
}
