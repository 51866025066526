import React, { useState } from 'react';

function RuleEntryComponent({ noRule, setNoRule, noRuleOrders, setNoRuleOrders, manufacturer, user, saveManufacturerCutRules ,isRuleMap}) {
  const [fractionValues, setFractionValues] = useState({
    bottomBarWhole: 0,
    bottomBarFraction: "0",
    bottomTubeWhole: 0,
    bottomTubeFraction: "0",
    cassetteCutWhole: 0,
    cassetteCutFraction: "0",
    fabricWidthWhole: 0,
    fabricWidthFraction: "0",
    tubeCutWhole: 0,
    tubeCutFraction: "0",
    fabricLengthWhole: 0,
    fabricLengthFraction: "0",
  });



  const [cmValues, setCmValues] = useState({
    bottomBar: '',
    bottomTube: '',
    cassetteCut: '',
    fabricWidth: '',
    fabricLength: '',
    tubeCut: '',
  });

  if (!noRule) return null;

  const handleFractionChange = (e) => {
    const { id, value } = e.target;
    setFractionValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleCmChange = (e) => {
    const { id, value } = e.target;
    setCmValues((prev) => ({ ...prev, [id]: value }));
  };

  const convertToDecimal = (whole, fraction) => {
    return parseFloat(whole) + parseFloat(fraction);
  };



  const handleSave = (order) => {
    let rules = {};

    if (order.variants) {
      Object.keys(order.variants).forEach((key) => {
        rules[key] = order.variants[key].value;
      });
    }

    const data = {
      product_type: order.selectedProduct.design_type,
      rules: rules,
      ruleName: document.getElementById('ruleName').value || 'Rule',
      creator: user.manufacturerID,
      metricSystem: manufacturer.metricSystem,
      deductions: manufacturer.metricSystem === "cm" ? {
        bottomBar: cmValues.bottomBar === "none" ? "none" : parseFloat(cmValues.bottomBar || 0), 
        bottomTube: cmValues.bottomTube === "none" ? "none" : parseFloat(cmValues.bottomTube || 0),
        cassetteCut: cmValues.cassetteCut === "none" ? "none" : parseFloat(cmValues.cassetteCut || 0),
        fabricWidth: cmValues.fabricWidth === "none" ? "none" : parseFloat(cmValues.fabricWidth || 0),
        tubeCut: cmValues.tubeCut === "none" ? "none" : parseFloat(cmValues.tubeCut || 0),
        fabricLength: cmValues.fabricLength === "none" ? "none" : parseFloat(cmValues.fabricLength || 0),
      } : {
        bottomBar: fractionValues.bottomBarWhole === "none" ? "none" : convertToDecimal(fractionValues.bottomBarWhole, fractionValues.bottomBarFraction),
        bottomTube: fractionValues.bottomTubeWhole === "none" ? "none" : convertToDecimal(fractionValues.bottomTubeWhole, fractionValues.bottomTubeFraction),
        cassetteCut: fractionValues.cassetteCutWhole === "none" ? "none" : convertToDecimal(fractionValues.cassetteCutWhole, fractionValues.cassetteCutFraction),
        fabricWidth: fractionValues.fabricWidthWhole === "none" ? "none" : convertToDecimal(fractionValues.fabricWidthWhole, fractionValues.fabricWidthFraction),
        tubeCut: fractionValues.tubeCutWhole === "none" ? "none" : convertToDecimal(fractionValues.tubeCutWhole, fractionValues.tubeCutFraction),
        fabricLength: fractionValues.fabricLengthWhole === "none" ? "none" : convertToDecimal(fractionValues.fabricLengthWhole, fractionValues.fabricLengthFraction ),
      },
      fractionValues: manufacturer.metricSystem === "inch" ? {
        bottomBarWhole: fractionValues.bottomBarWhole,
        bottomBarFraction: fractionValues.bottomBarFraction,
        bottomTubeWhole: fractionValues.bottomTubeWhole,
        bottomTubeFraction: fractionValues.bottomTubeFraction,
        cassetteCutWhole: fractionValues.cassetteCutWhole,
        cassetteCutFraction: fractionValues.cassetteCutFraction,
        fabricWidthWhole: fractionValues.fabricWidthWhole,
        fabricWidthFraction: fractionValues.fabricWidthFraction,
        tubeCutWhole: fractionValues.tubeCutWhole,
        tubeCutFraction: fractionValues.tubeCutFraction,
        fabricLengthWhole: fractionValues.fabricLengthWhole,
        fabricLengthFraction: fractionValues.fabricLengthFraction,
      } : {
        bottomBarWhole: cmValues.bottomBar,
        bottomBarFraction: 0,
        bottomTubeWhole: cmValues.bottomTube,
        bottomTubeFraction: 0,
        cassetteCutWhole: cmValues.cassetteCut,
        cassetteCutFraction: 0,
        fabricWidthWhole: cmValues.fabricWidth,
        fabricWidthFraction: 0,
        tubeCutWhole: cmValues.tubeCut,
        tubeCutFraction: 0,
        fabricLengthWhole: cmValues.fabricLength,
        fabricLengthFraction: 0,
      },
    };


    saveManufacturerCutRules(data).then((res) => {
      if (res) {
        setNoRule(false);
        setNoRuleOrders([]);
      }
    });
  };



  const renderInchInput = (idWhole, idFraction, label, onClickNotApplicableInch) => (
    <div className="flex flex-col space-y-2">
      <label htmlFor={idWhole} className="text-gray-700 font-medium">
        {label} 
      </label>
  
      <div className='flex flex-row items-center'>
      <input type='checkbox' id='notApplicable' name='notApplicable' value='notApplicable' onClick={onClickNotApplicableInch(idWhole,idFraction)} />
      <p className='ml-2'>N/A</p>
      </div>
 
      <div className="flex space-x-2">
        <input
          type="number"
          placeholder="Inches"
          id={idWhole}
          
          value={fractionValues[idWhole]}
          disabled={fractionValues[idWhole] === "none"}
          onChange={handleFractionChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/3 p-2.5"
        />
        <select
          id={idFraction}
          value={fractionValues[idFraction]}
          onChange={handleFractionChange}
          disabled={fractionValues[idWhole] === "none"}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5"
        >
          <option value="0">0</option>
          <option value="0.0625">1/16</option>
          <option value="0.125">1/8</option>
          <option value="0.1875">3/16</option>
          <option value="0.25">1/4</option>
          <option value="0.3125">5/16</option>
          <option value="0.375">3/8</option>
          <option value="0.4375">7/16</option>
          <option value="0.5">1/2</option>
          <option value="0.5625">9/16</option>
          <option value="0.625">5/8</option>
          <option value="0.6875">11/16</option>
          <option value="0.75">3/4</option>
          <option value="0.8125">13/16</option>
          <option value="0.875">7/8</option>
          <option value="0.9375">15/16</option>
        </select>
      </div>
    </div>
  );

  const renderCmInput = (id, label,onClickNotApplicableCm) => (
    <div className="flex flex-col space-y-2">
      <label htmlFor={id} className="text-gray-700 font-medium">
        {label}
      </label>
      <div className='flex flex-row items-center'>
      <input type='checkbox' id='notApplicable' name='notApplicable' value='notApplicable' onClick={onClickNotApplicableCm(id)} />
      <p className='ml-2'>N/A</p>
      </div>
      <input
        type="number"
        placeholder={`${label} (cm)`}
        id={id}
        value={cmValues[id]}
        onChange={handleCmChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
    </div>
  );


  const onClickNotApplicableInch = (idWhole,idFraction) => (e) => {
    if (e.target.checked) {
      
      setFractionValues((prev) => ({ ...prev, [idWhole]: "none" }));
      setFractionValues((prev) => ({ ...prev, [idFraction]: "none" }));
    }else{
      setFractionValues((prev) => ({ ...prev, [idWhole]: 0 }));
      setFractionValues((prev) => ({ ...prev, [idFraction]: "0" }));
    }
  }

  const onClickNotApplicableCm = (id) => (e) => {
    if (e.target.checked) {
      setCmValues((prev) => ({ ...prev, [id]: "none" }));
    }else{
      setCmValues((prev) => ({ ...prev, [id]: 0 }));
    }
  }



  return (
    <div className="flex flex-row justify-center ">
      <div className="bg-white shadow-lg rounded-lg p-8 sm:w-2/3 md:w-1/2 lg:w-1/3">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-6">Define Your Rule</h1>
        <button
          size="small"
          onClick={() => setNoRule(false)}
          className="bg-gradient-to-r from-blue-400 to-purple-500 hover:from-blue-500 hover:to-purple-600 text-white font-semibold py-2 px-4 rounded-md mb-6 transition ease-in-out duration-200"
        >
          Back
        </button>
        {noRuleOrders.slice(0, 1).map((order) => (
          <div key={order.id} className="space-y-6">
            {order.variants &&
              Object.keys(order.variants).map((key) => (
                isRuleMap && isRuleMap[key] &&
                <div key={key} className="flex justify-between items-center">
                  <p className="text-gray-700 font-medium">{key}</p>
                  <p className="text-gray-900">{order.variants[key].value}</p>
                </div>
              ))}

            <div className="space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="ruleName" className="text-gray-700 font-medium">
                  Rule Name
                </label>
                <input
                  type="text"
                  placeholder="Enter rule name"
                  id="ruleName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>
              {manufacturer.metricSystem === "cm" ? (
                <>
                  {renderCmInput("cassetteCut", "Cassette Cut",onClickNotApplicableCm)}
                  {renderCmInput("tubeCut", "Tube Cut",onClickNotApplicableCm)}
                  {renderCmInput("bottomBar", "Bottom Bar",onClickNotApplicableCm)}
                  {renderCmInput("bottomTube", "Bottom Tube",onClickNotApplicableCm)}
                  {renderCmInput("fabricWidth", "Fabric Width",onClickNotApplicableCm)}
                  {renderCmInput("fabricLength", "Fabric Height",onClickNotApplicableCm)}
                </>
              ) : (
                <>
                  {renderInchInput("bottomBarWhole", "bottomBarFraction", "Bottom Bar (deduction)",onClickNotApplicableInch)}
                  {renderInchInput("bottomTubeWhole", "bottomTubeFraction", "Bottom Tube (deduction)",onClickNotApplicableInch)}
                  {renderInchInput("cassetteCutWhole", "cassetteCutFraction", "Cassette Cut (deduction)",onClickNotApplicableInch)}
                  {renderInchInput("fabricWidthWhole", "fabricWidthFraction", "Fabric Width (deduction)",onClickNotApplicableInch)}
                  {renderInchInput("tubeCutWhole", "tubeCutFraction", "Tube Cut (deduction)",onClickNotApplicableInch)}
                  {renderInchInput("fabricLengthWhole", "fabricLengthFraction", "Fabric Length (addition)",onClickNotApplicableInch)}
                </>
              )}
            </div>
            <button
              className="bg-gradient-to-r from-blue-400 to-purple-500 hover:from-blue-500 hover:to-purple-600 text-white font-semibold py-2 px-4 rounded-md w-full mt-6 transition ease-in-out duration-200"
              onClick={() => handleSave(order)}
            >
              Save
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RuleEntryComponent;
