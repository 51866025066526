import React, { useEffect, useState } from 'react';
import {
  getGlobalRules,
  updateGlobalRule,
  deleteManufactureRule,
  globalSuccess,
  getAllGlobalVariations
} from '../../utils/globalFunctions';
import { set } from 'lodash';

export default function NewRules({ user, manufacturer }) {
  const [savedRules, setSavedRules] = useState([]);
  const [fractionValues, setFractionValues] = useState({});
  const [deductionValues, setDeductionValues] = useState({});
  const [isRuleMap, setIsRuleMap] = useState({});
  const [editedRuleNames, setEditedRuleNames] = useState({}); 
  const [searchTerm, setSearchTerm] = useState(''); // <-- NEW state for search

  useEffect(() => {
    getRules();

    getAllGlobalVariations(user.manufacturerID).then((data_) => {
      let map = {};
      let map1 = {};
      let history = [];
      data_.forEach((item) => {
        history.push(item);
        // Check if the key is already in the map
        if (map[item.variation_title]) {
          // Find the object in the history array
          let obj = history.find(
            (o) => o.variation_title === item.variation_title
          );
          // If the object is found, check if it's deleted and update the map with the latest object
          if (obj.isDeleted && !item.isDeleted) {
            map[item.variation_title] = item?.key ? item.key : 'Customizations';
            map1[item.variation_title] = item?.isRule ? item.isRule : false;

            // Remove the old object from the history array
            history = history.filter(
              (o) => o.variation_title !== item.variation_title
            );

            // Add the new object to the history array
            history.push(item);
          }
        } else {
          map[item.variation_title] = item?.key
            ? item.key
            : item.variation_title;
          map1[item.variation_title] = item?.isRule ? item.isRule : false;
        }
      });
      setIsRuleMap(map1);
    });
  }, []);

  const getRules = () => {
    getGlobalRules(user.manufacturerID).then((res) => {
      setSavedRules(res);

      const loadedFractionValues = {};
      const loadedDeductionValues = {};
      res.forEach((rule) => {
        if (rule.metricSystem === 'inch') {
          if (rule.fractionValues) {
            loadedFractionValues[rule.id] = rule.fractionValues;
          }
        } else {
          if (rule.deductions) {
            loadedDeductionValues[rule.id] = rule.deductions;
          }
        }
      });

      setFractionValues(loadedFractionValues);
      setDeductionValues(loadedDeductionValues);
    });
  };

  const handleFractionChange = (ruleId, key, part, value) => {
    let numericValue;
    if (part === 'Fraction') {
      // Allow numbers and decimal points for fractions
      numericValue = value.replace(/[^0-9.]/g, '');
    } else {
      // Allow only numbers for whole parts
      numericValue = value.replace(/[^0-9]/g, '');
    }

    setFractionValues((prevValues) => ({
      ...prevValues,
      [ruleId]: {
        ...prevValues[ruleId],
        [key + part]: numericValue,
      },
    }));
  };

  const handleCmChange = (ruleId, key, value) => {
    // Allow only numbers and decimal points
    const numericValue = value.replace(/[^0-9.]/g, '');
    setDeductionValues((prevValues) => ({
      ...prevValues,
      [ruleId]: {
        ...prevValues[ruleId],
        [key]: numericValue,
      },
    }));
  };

  const onClickNotApplicable = (ruleId, key, cmOrInch) => (e) => {
    if (cmOrInch === 'inch') {
      if (e.target.checked) {
        setFractionValues((prevValues) => ({
          ...prevValues,
          [ruleId]: {
            ...prevValues[ruleId],
            [key + 'Whole']: 'none',
            [key + 'Fraction']: 'none',
          },
        }));
      } else {
        setFractionValues((prevValues) => ({
          ...prevValues,
          [ruleId]: {
            ...prevValues[ruleId],
            [key + 'Whole']: '',
            [key + 'Fraction']: '0',
          },
        }));
      }
    } else {
      if (e.target.checked) {
        setDeductionValues((prevValues) => ({
          ...prevValues,
          [ruleId]: {
            ...prevValues[ruleId],
            [key]: 'none',
          },
        }));
      } else {
        setDeductionValues((prevValues) => ({
          ...prevValues,
          [ruleId]: {
            ...prevValues[ruleId],
            [key]: 0,
          },
        }));
      }
    }
  };

  const renderInchInput = (ruleId, key) => {
    const wholeValue = fractionValues[ruleId]?.[key + 'Whole'] ?? '';
    const fractionValue = fractionValues[ruleId]?.[key + 'Fraction'] ?? '';

    return (
      <div className="flex space-x-2 items-center">
        <div className="flex flex-col">
          <label htmlFor={key} className="text-sm font-medium text-gray-600">
            N/A
          </label>
          <input
            type="checkbox"
            id={`notApplicable-${ruleId}-${key}-inch`}
            name="notApplicable"
            onClick={onClickNotApplicable(ruleId, key, 'inch')}
            checked={wholeValue === 'none'}
          />
        </div>
        <input
          type="text"
          value={wholeValue}
          disabled={wholeValue === 'none'}
          onChange={(e) => handleFractionChange(ruleId, key, 'Whole', e.target.value)}
          placeholder="Inches"
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-2/3"
        />
        <select
          value={fractionValue}
          disabled={fractionValue === 'none'}
          onChange={(e) => handleFractionChange(ruleId, key, 'Fraction', e.target.value)}
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 w-1/3"
        >
          <option value="0">0</option>
          <option value="0.0625">1/16</option>
          <option value="0.125">1/8</option>
          <option value="0.1875">3/16</option>
          <option value="0.25">1/4</option>
          <option value="0.3125">5/16</option>
          <option value="0.375">3/8</option>
          <option value="0.4375">7/16</option>
          <option value="0.5">1/2</option>
          <option value="0.5625">9/16</option>
          <option value="0.625">5/8</option>
          <option value="0.6875">11/16</option>
          <option value="0.75">3/4</option>
          <option value="0.8125">13/16</option>
          <option value="0.875">7/8</option>
          <option value="0.9375">15/16</option>
        </select>
      </div>
    );
  };

  const renderCmInput = (ruleId, key) => {
    const cmValue = deductionValues[ruleId]?.[key] ?? '';

    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <label htmlFor={key} className="text-sm font-medium text-gray-600">
            N/A
          </label>
          <input
            type="checkbox"
            id={`notApplicable-${ruleId}-${key}-cm`}
            name="notApplicable"
            onClick={onClickNotApplicable(ruleId, key, 'cm')}
            checked={cmValue === 'none'}
          />
        </div>
        <input
          type="text"
          value={cmValue}
          disabled={cmValue === 'none'}
          onChange={(e) => handleCmChange(ruleId, key, e.target.value)}
          placeholder="cm"
          className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
    );
  };

  const handleRuleNameChange = (ruleId, newName) => {
    setEditedRuleNames((prev) => ({
      ...prev,
      [ruleId]: newName,
    }));
  };

  const handleSave = (rule) => {
    const ruleId = rule.id;
    const updatedRuleName = editedRuleNames[ruleId] || rule.ruleName;

    let updatedRule = { ...rule };
    updatedRule.ruleName = updatedRuleName; // Update the rule name

    // If manufacturer metric is inch, convert fraction-based values
    if (manufacturer.metricSystem === 'inch') {
      const updatedFractionValues = fractionValues[ruleId] || {};
      updatedRule.fractionValues = updatedFractionValues;

      // Convert fraction + whole to single decimal for each key
      updatedRule.deductions = {
        bottomBar:
          updatedFractionValues.bottomBarWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.bottomBarWhole || 0) +
              parseFloat(updatedFractionValues.bottomBarFraction || 0),
        bottomTube:
          updatedFractionValues.bottomTubeWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.bottomTubeWhole || 0) +
              parseFloat(updatedFractionValues.bottomTubeFraction || 0),
        cassetteCut:
          updatedFractionValues.cassetteCutWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.cassetteCutWhole || 0) +
              parseFloat(updatedFractionValues.cassetteCutFraction || 0),
        fabricWidth:
          updatedFractionValues.fabricWidthWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.fabricWidthWhole || 0) +
              parseFloat(updatedFractionValues.fabricWidthFraction || 0),
        tubeCut:
          updatedFractionValues.tubeCutWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.tubeCutWhole || 0) +
              parseFloat(updatedFractionValues.tubeCutFraction || 0),
        fabricLength:
          updatedFractionValues.fabricLengthWhole === 'none'
            ? 'none'
            : parseFloat(updatedFractionValues.fabricLengthWhole || 0) +
              parseFloat(updatedFractionValues.fabricLengthFraction || 0),
      };
    } else {
      // If metric is cm, store them directly
      const updatedDeductionValues = deductionValues[ruleId] || {};
      updatedRule.deductions = updatedDeductionValues;
    }

    updateGlobalRule(updatedRule)
      .then((res) => {
        if (res) {
          globalSuccess('Rule updated successfully');
          // Clear the edited rule name after successful update
          setEditedRuleNames((prev) => {
            const updated = { ...prev };
            delete updated[ruleId];
            return updated;
          });
          getRules();
        }
      })
      .catch((error) => {
        // Handle error appropriately
        console.error('Error updating rule:', error);
      });
  };

  // Filter rules by the `searchTerm` in the ruleName
  const filteredRules = savedRules.filter((rule) =>
    rule?.ruleName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          Production Manufacturer Cut Rules
        </h1>

        {/* SEARCH INPUT */}
        <div className="mb-6">
          <label className="block text-md font-medium text-gray-700 mb-2">
            Search by Title:
          </label>
          <input
            type="text"
            placeholder="Enter rule title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        {filteredRules.length > 0 ? (
          filteredRules.map((rule) => (
            <div key={rule.id} className="mb-8 border-b shadow-lg p-8">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-700 mb-4 flex items-center w-full">
                  {/* Editable Rule Name */}
                  <input
                    type="text"
                    value={editedRuleNames[rule.id] || rule.ruleName}
                    onChange={(e) => handleRuleNameChange(rule.id, e.target.value)}
                    className="border-b w-full border-gray-300 focus:outline-none focus:border-indigo-500 mr-2"
                  />
                  <span className="text-gray-500"> ({rule.product_type})</span>
                </h2>
              </div>

              <div className="border-b border-t py-4">
                {Object.keys(rule.rules)
                  .sort()
                  .map(
                    (key) =>
                      isRuleMap[key] && (
                        <div key={key} className="flex flex-col mb-2">
                          <label
                            htmlFor={key}
                            className="text-sm font-medium text-gray-600"
                          >
                            {key} : {rule.rules[key]}
                          </label>
                        </div>
                      )
                  )}
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4">
                {Object.keys(rule.deductions).map((key) => (
                  <div key={key} className="flex flex-col">
                    <label
                      htmlFor={key}
                      className="text-sm font-medium text-gray-600"
                    >
                      {key}
                    </label>
                    {manufacturer.metricSystem === 'inch'
                      ? renderInchInput(rule.id, key)
                      : renderCmInput(rule.id, key)}
                  </div>
                ))}
              </div>

              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => handleSave(rule)}
                  className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                >
                  Update Rule
                </button>
                <button
                  onClick={() => {
                    deleteManufactureRule(rule.id).then((res) => {
                      if (res) {
                        globalSuccess('Rule has been deleted successfully');
                        getRules();
                      }
                    });
                  }}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
                >
                  Delete Rule
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No rules found.</p>
        )}
      </div>
    </div>
  );
}