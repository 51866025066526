import { 
  PDFDocument, 
  PDFName, 
  StandardFonts, 
  rgb 
} from "pdf-lib";

/**
 * Flattens the customizations object into a single-level object.
 * @param {Object} customizations - The customizations object.
 * @returns {Object} - The flattened customizations.
 */
const flattenCustomizations = (customizations) => {
  const flattened = {};
  Object.values(customizations).forEach((customization) => {
    Object.entries(customization).forEach(([key, value]) => {
      if (typeof value === "object" && value.details) {
        flattened[key] = value.value;
        value.details.forEach((detail) => {
          flattened[`${key}.${detail.key}`] = detail.value;
        });
      } else {
        flattened[key] = value.value || value;
      }
    });
  });
  return flattened;
};

/**
 * Converts a decimal measurement to a fraction.
 * @param {String|Number} decimal - The decimal value to convert.
 * @returns {Object} - An object containing the whole number and fraction.
 */
const convertToFraction = (decimal) => {
  console.log("Converting to fraction:", decimal);
  if (decimal === "none") {
    return {
      whole: 0,
      fraction: 0,
    };
  }

  let inch_decimal_ = cmToInches(decimal);
  const wholeNumber = Math.floor(inch_decimal_);
  const fractionDecimal = inch_decimal_ - wholeNumber;
  const closestFraction = convertToClosestFraction(fractionDecimal, 16); // Using 16 as the maximum denominator

  return {
    whole: wholeNumber,
    fraction: closestFraction,
  };
};

/**
 * Converts whole and fraction parts to a decimal.
 * @param {String|Number} whole - The whole number part.
 * @param {String} fraction - The fraction part (e.g., "1/4").
 * @returns {Number} - The combined decimal value.
 */
const convertToDecimal = (whole, fraction) => {
  // Convert the whole part to a number
  const wholeNumber = parseFloat(whole);

  // If fraction is a valid fraction string (like "1/4"), split and calculate it
  if (fraction && fraction.includes("/")) {
    const [numerator, denominator] = fraction.split("/").map(Number);
    return wholeNumber + numerator / denominator;
  }

  // If no fraction or fraction is not a valid format, just return the whole number
  return wholeNumber;
};

/**
 * Converts inches to centimeters.
 * @param {Number} inches - The value in inches.
 * @returns {Number} - The converted value in centimeters.
 */
const inchesToCm = (inches) => {
  return inches * 2.54;
};

/**
 * Converts centimeters to inches.
 * @param {Number} cm - The value in centimeters.
 * @returns {Number} - The converted value in inches.
 */
const cmToInches = (cm) => {
  return cm / 2.54;
};

/**
 * Converts a decimal to the closest fraction based on the maximum denominator.
 * @param {Number} fraction - The decimal fraction to convert (between 0 and 1).
 * @param {Number} maxDenominator - The maximum denominator to consider.
 * @returns {String|Number} - The closest fraction as a string or a whole number if applicable.
 */
const convertToClosestFraction = (fraction, maxDenominator = 16) => {
  const denominator = maxDenominator;
  const numerator = Math.round(fraction * denominator);

  // If the numerator is 0 or equal to the denominator, adjust the fraction
  if (numerator === 0) {
    return "";
  } else if (numerator === denominator) {
    return `${numerator / denominator}`;
  } else {
    // Reduce the fraction to its simplest form
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(numerator, denominator);
    return `${numerator / divisor}/${denominator / divisor}`;
  }
};

/**
 * Generates a PDF with labels, each containing a border, and attempts to avoid
 * "shrink to fit" by setting proper page size and viewer preferences.
 * 
 * @param {Array} data - Array of items to generate labels for.
 * @param {Object} orderInfo - Information about the order.
 * @param {String} labelType - Type of label to generate.
 * @param {Function} setOpen - Function to set the open state (UI related).
 * @param {Function} setLoadingModal - Function to set the loading modal state.
 * @param {Object} manufacturer - Manufacturer details including label settings.
 */
export const handleGenerateLabel = async (
  data,
  orderInfo,
  labelType,
  setOpen,
  setLoadingModal,
  manufacturer
) => {
  try {
    setLoadingModal(true); // Start loading modal

    // Label specs (8.5" x 11" in points = 612 x 792).
    // Adjust margins to ensure you're not too close to edges.
    const labelSpecs = {
      pageWidth: 612,  // 8.5 in * 72
      pageHeight: 792, // 11 in * 72
      labelWidth: 189,
      labelHeight: 72,
      leftMargin: 15.822,
      topMargin: 36,
      gapX: 6.678,
      gapY: 0,
      paddingY: 8,
      labelsPerRow: 3,
      labelsPerColumn: 10,
      fontSize: 9,
      topSpace: 2.5,
      lineSpace: 2.75,
    };

    const {
      pageWidth,
      pageHeight,
      labelWidth,
      labelHeight,
      leftMargin,
      topMargin,
      gapX,
      gapY,
      paddingY,
      labelsPerRow,
      labelsPerColumn,
    } = labelSpecs;

    // 1) Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // 2) Force "PrintScaling" to None (so Acrobat/Adobe won't auto-scale).
    //    Note: Not all PDF viewers obey this, but many do.
    pdfDoc.catalog.set(
      PDFName.of("ViewerPreferences"),
      pdfDoc.context.obj({
        PrintScaling: PDFName.of("None"),
      })
    );

    // Embed the Helvetica font
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // If you still want to skip certain labels:
    // (Some users might prefer removing the prompt or giving a simpler UI)
    const labelsToSkip =
      parseInt(prompt("How many labels would you like to skip?", "0"), 10) || 0;

    console.log("data", data);
    const flatArray = data;
    console.log("data", flatArray);

    // Expand the data array based on quantity
    const newArray = flatArray.reduce((acc, item) => {
      for (let i = 0; i < item.quantity; i++) {
        acc.push(item);
      }
      return acc;
    }, []);

    const totalLabels = labelsToSkip + newArray.length;
    const labelsPerPage = labelsPerRow * labelsPerColumn;
    const totalPages = Math.ceil(totalLabels / labelsPerPage);

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      for (let labelIndex = 0; labelIndex < labelsPerPage; labelIndex++) {
        const globalLabelIndex = pageIndex * labelsPerPage + labelIndex;
        if (globalLabelIndex >= totalLabels) break;

        const actualIndex = globalLabelIndex - labelsToSkip;
        if (actualIndex >= 0 && actualIndex < newArray.length) {
          const item = newArray[actualIndex];

          // Calculate row and column
          const row = Math.floor(labelIndex / labelsPerRow);
          const col = labelIndex % labelsPerRow;

          // Calculate x and y positions
          const x = leftMargin + col * (labelWidth + gapX);
          const y = pageHeight - topMargin - (row + 1) * labelHeight - row * gapY;

          // // Draw a border rectangle for the label
          // page.drawRectangle({
          //   x,
          //   y,
          //   width: labelWidth,
          //   height: labelHeight,
          //   borderColor: rgb(0, 0, 0), // Black border
          //   borderWidth: 1,
          // });

          // Metric system conversions if needed
          if (
            manufacturer.metricSystem === "inch" &&
            item.metricSystem === "cm"
          ) {
            console.log("Converting cm to inches for item:", item);
            let inchWidth = convertToFraction(item.width);
            let inchHeight = convertToFraction(item.height);
            item.width = inchWidth.whole;
            item.height = inchHeight.whole;
            item.fractions = {
              width: inchWidth.fraction,
              height: inchHeight.fraction,
            };
          } else if (
            manufacturer.metricSystem === "cm" &&
            item.metricSystem === "inch"
          ) {
            item.width = convertToDecimal(item.width, item.fractions?.width);
            item.height = convertToDecimal(item.height, item.fractions?.height);
          }

          // Prepare measurement string
          const measurements =
            manufacturer.metricSystem === "inch"
              ? `${item.width} ${item.fractions?.width} x ${item.height} ${item.fractions?.height} inch`
              : `${item.width} x ${item.height} cm`;

          // Map data for label settings
          const dataMapForSettings = {
            "Item No": `Item #${item.index}`,
            "Store Name": orderInfo.store_name,
            "Customer Name": orderInfo.customer_name,
            "Fabric Name": item.fabric_name,
            "Room Name": item.room_name,
            "Measurement": measurements,
            "Date": orderInfo.created_at
              .toDate()
              .toLocaleDateString(),
            ...flattenCustomizations(item.customizations),
          };

          console.log("Data Map for Settings:", dataMapForSettings);

          // Dynamically generate label lines based on labelSettings
          let yOffset = 0;
          const { labelSettings } = manufacturer;
          labelSettings.forEach((lineConfig) => {
            let lineContent = lineConfig.fields
              .map((field) =>
                dataMapForSettings[field.keyName]
                  ? (field.prefix || "") + dataMapForSettings[field.keyName]
                  : ""
              )
              .join(" ");

            const fontSize = 9; // or your chosen size
            lineContent = lineContent.replace(/\t/g, '');
            const textWidth = font.widthOfTextAtSize(lineContent, fontSize);
            const textX = x + (labelWidth - textWidth) / 2;

            // Calculate text Y position, from the label's top downward
            const textY = y + labelHeight - paddingY - yOffset - fontSize;

            page.drawText(lineContent, {
              x: textX,
              y: textY,
              size: fontSize,
              font,
              color: rgb(0, 0, 0),
            });

            // Move down for next line
            yOffset += fontSize + labelSpecs.lineSpace;
          });
        }
      }
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Create a Blob from the PDF bytes
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "labels.pdf";
    link.click();

    // Close the modal and reset loading state
    setLoadingModal(false);
    setOpen(false);
  } catch (error) {
    console.error("Error generating labels:", error);
    setLoadingModal(false);
    setOpen(false);
    // Optionally, display an error message to the user
    alert("An error occurred while generating labels. Please try again.");
  }
};